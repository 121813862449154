<i18n>
{
  "de": {
    "editUserTitle": "Benutzer bearbeiten",
    "firstNameLabel": "Vorname",
    "lastNameLabel": "Nachname",
    "companyNameLabel": "Firma",
    "emailLabel": "E-Mail",
    "submitButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen"
  }
}
</i18n>

<template>
  <main-layout class="admin-edit-user-page" :ready="allUsersReady">
    <template #default>
      <p v-if="error">{{ error }}</p>
      <h1>{{ $t('editUserTitle') }}</h1>
      <form>
        <label for="user-first-name-field">{{ $t('firstNameLabel') }}</label>
        <input id="user-first-name-field" type="text" v-model="firstName">
        <label for="user-last-name-field">{{ $t('lastNameLabel') }}</label>
        <input id="user-last-name-field" type="text" v-model="lastName">
        <label for="user-company-name-field">{{ $t('companyNameLabel') }}</label>
        <input id="user-company-name-field" type="text" v-model="companyName">
        <label for="user-email-field">{{ $t('emailLabel') }}</label>
        <input id="user-email-field" type="text" v-model="email">
      </form>
      <button-wrapper>
        <button class="button" :disabled="!submitButtonEnabled" @click.prevent="onSubmit">{{ $t('submitButtonLabel') }}</button>
        <button class="button" @click.prevent="onCancel">{{ $t('cancelButtonLabel') }}</button>
      </button-wrapper>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin'
import AdminUserMixin from '@/pages/vuex-mixins/AdminUserMixin'

import ButtonWrapper from '@/components/shared/ButtonWrapper'
import { mapActions } from 'vuex'

export default {
  mixins: [
    AdminAllUsersMixin, // Provides: allUsers, allUsersReady
    AdminUserMixin // Provides: user
  ],
  data () {
    return {
      firstName: null,
      lastName: null,
      companyName: null,
      email: null,
      error: null
    }
  },
  components: {
    ButtonWrapper,
    MainLayout
  },
  computed: {
    submitButtonEnabled () {
      return Boolean(this.firstName && this.lastName && this.email)
    }
  },
  watch: {
    user (val, oldVal) {
      this.userChanged()
    }
  },
  created () {
    this.userChanged()
  },
  methods: {
    ...mapActions({
      updateUser: 'users/updateUser'
    }),
    userChanged () {
      if (this.user) {
        this.firstName = this.user.first_name
        this.lastName = this.user.last_name
        this.companyName = this.user.company_name
        this.email = this.user.email
      }
    },
    async onSubmit () {
      try {
        await this.updateUser({
          id: this.user.id,
          first_name: this.firstName,
          last_name: this.lastName,
          company_name: this.companyName,
          email: this.email
        })
        this.$router.push({ name: 'adminUser', params: { user_id: this.user.id } })
      } catch (error) {
        this.error = error
      }
    },
    onCancel () {
      this.$router.push({ name: 'adminUser', params: { user_id: this.user.id } })
    }
  }
}
</script>

<style>
.admin-edit-user-page form {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-m);
  column-gap: var(--spacing-l);
}

</style>
